<template>
  <v-container>
    <div v-for="(action, i) in actions" v-bind:key="action.id">
      <v-row>
        <v-col>
          <h3>Action Item #{{ i + 1 }} - {{ action.Statement }}</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="8">
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="action.Method"
                readonly
                label="Method"
                class="v-text-field-readonly"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="action.Action"
                readonly
                label="Action"
                class="v-text-field-readonly"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="action.ActionedBy.Name"
                readonly
                label="ActionedBy"
                class="v-text-field-readonly"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="action.Collaborate"
                readonly
                label="Collaborate"
                class="v-text-field-readonly"
              />
            </v-col>

            <v-col cols="6">
              <v-text-field
                v-model="action.Category"
                readonly
                label="Category"
                class="v-text-field-readonly"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="action.Training"
                readonly
                label="Training"
                class="v-text-field-readonly"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="action.Notes"
                readonly
                label="Notes"
                class="v-text-field-readonly"
              />
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="4">
          <v-row>
            <v-col>
              <h2>Action Due By</h2>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-menu
                v-model="menu[i]"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="action.DeadlineDate"
                    label="Select a date"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @change="updateActionValue()"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="action.DeadlineDate"
                  @input="menu[i] = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <h2>Action Status</h2>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                v-model="action.Status"
                :items="['Blocked', 'Complete', 'In Progress', 'Not Started']"
                label="Select an option"
                @change="updateActionValue()"
              ></v-select>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col>
          <v-expansion-panels accordion v-model="panels" multiple>
            <v-expansion-panel v-for="(item, j) in 1" :key="j">
              <v-expansion-panel-header
                >Action updates</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="11">
                    <!-- add update input -->

                    <v-text-field
                      v-model="tempUpdate[i]"
                      label="Add an update..."
                    ></v-text-field>
                  </v-col>
                  <v-col cols="1">
                    <v-btn
                      icon
                      @click="appendItem(tempUpdate[i], action.Id, i)"
                    >
                      <v-icon x-large color="#2092D3"
                        >mdi-plus-circle-outline
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <div v-if="action.Updates.length > 0">
                      <!-- action update display list -->
                      <v-data-table
                        :headers="updateHeaders"
                        :items="action.Updates"
                        hide-default-footer
                        class="elevation-1"
                        disable-pagination
                      >
                        <template v-slot:item="row">
                          <tr>
                            <td>{{ truncateString(row.item.Content) }}</td>

                            <td>{{ formatDate(row.item.DateAdded) }}</td>

                            <td class="tableActions">
                              <v-btn
                                icon
                                @click="
                                  editDialog = true;
                                  selectedItem = row.item;
                                  newItem = copyItem(row.item);
                                  tempId = action.Id;
                                "
                                class="mdi-pencil"
                              >
                              </v-btn>
                            </td>
                            <td class="tableActions">
                              <v-btn
                                icon
                                @click="
                                  confirmDeleteDialog = true;
                                  selectedItem = row.item;
                                  tempId = action.Id;
                                "
                                class="mdi-trash-can-outline"
                              >
                              </v-btn>
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </div>
                    <!-- action display actions buttons  -->
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-divider></v-divider>
        </v-col>
      </v-row>
    </div>

    <!-- DELETE dialog -->

    <v-dialog v-model="confirmDeleteDialog" max-width="320">
      <v-card>
        <v-card-title class="headline"
          >Confirm Action Plan update delete</v-card-title
        >
        <v-card-text
          >Are you sure you would like to delete this action plan update
          item?</v-card-text
        >
        <v-card-actions>
          <v-btn
            color="red darken-3"
            text
            @click="
              confirmDeleteDialog = false;
              selectedItem = null;
            "
            >Cancel</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="
              confirmDeleteDialog = false;
              deleteItem(selectedItem);
            "
            >Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- EDIT dialog -->

    <v-dialog v-model="editDialog" max-width="600">
      <v-card>
        <v-card-title class="headline">Edit Action Plan update</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field
                :value="newItem.Content"
                @change="(v) => (newItem.Content = v)"
                label="Edit the update"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="red darken-3" text @click="editDialog = false"
            >Cancel</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="
              editDialog = false;
              editItem(selectedItem, newItem);
            "
            >Save changes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import _ from "lodash";
import dayjs from "dayjs";

export default {
  name: "App",
  props: {},
  components: {},

  data: () => ({
    panels: [],
    menu: [],
    updateHeaders: [
      { text: "Content", value: "Content", sortable: true },
      {
        text: "DateAdded",
        value: "DateAdded",
        dataType: "Date",
        sortable: true,
      },
      { text: "", value: "Edit", sortable: false },
      { text: "", value: "Delete", sortable: false },
    ],
    tempUpdate: [],
    confirmed: false,
    actions: [],
    confirmDeleteDialog: false,
    editDialog: false,
    tempId: null,
    newItem: {
      Id: null,
      Content: null,
      DateAdded: null,
    },
    selectedItem: {
      Id: null,
      Content: null,
      DateAdded: null,
    },
  }),
  mounted() {
    this.actions = this.$store.state.plan.DigitalPlan.ActionPlan;
  },
  created() {
    this.actions = this.$store.state.plan.DigitalPlan.ActionPlan;
    this.all();
    this.actions.forEach((element) => {
      this.tempUpdate.push("");
    });
  },
  computed: {},
  methods: {
    truncateString(val) {
      if (val) {
        if (val.length > 120) {
          return val.match(/.{1,120}/g)[0].concat("...");
        } else {
          return val;
        }
      }
    },
    formatDate(date) {
      // return moment(date).format('MMMM Do YYYY')
      return dayjs(date).format("D MMM YYYY");
    },
    all() {
      this.panels = [...Array(this.items).keys()].map((k, i) => i);
    },
    copyItem(item) {
      return _.cloneDeep(item);
    },
    updateActionValue() {
      this.$store.state.plan.DigitalPlan.ActionPlan = this.actions;
      // console.log(JSON.stringify(this.$store.state.plan.DigitalPlan.ActionPlan))
    },
    appendItem(val, actionId, i) {
      // console.log(val)
      // copy aim
      //let newAim =  Object.create(aim);
      let newItem = {
        Id: null,
        Content: null,
        DateAdded: null,
      };
      // create new uuid for aim
      newItem.Id = this.$uuid.v4();
      newItem.DateAdded = new Date().toISOString();
      newItem.Content = val;

      const index = this.$store.state.plan.DigitalPlan.ActionPlan.findIndex(
        (x) => x.Id == actionId
      );

      this.$store.state.plan.DigitalPlan.ActionPlan[index].Updates.push(
        newItem
      );
      // console.log(this.tempUpdate)
      this.tempUpdate[i] = "";
      // console.log(this.tempUpdate)
    },
    editItem(selectedItem, newItem) {
      const actionIndex =
        this.$store.state.plan.DigitalPlan.ActionPlan.findIndex(
          (x) => x.Id == this.tempId
        );
      const updateIndex = this.$store.state.plan.DigitalPlan.ActionPlan[
        actionIndex
      ].Updates.findIndex((x) => x.Id == selectedItem.Id);
      if (updateIndex > -1) {
        this.$store.state.plan.DigitalPlan.ActionPlan[actionIndex].Updates[
          updateIndex
        ].Content = newItem.Content;
        this.$store.state.plan.DigitalPlan.ActionPlan[actionIndex].Updates[
          updateIndex
        ].DateAdded = new Date().toISOString();
        this.$store.state.plan.DigitalPlan.ActionPlan[actionIndex].Updates[
          updateIndex
        ].Id = newItem.Id;
        this.selectedItem = {
          Id: null,
          Content: null,
          DateAdded: null,
        };
        this.newItem = {
          Id: null,
          Content: null,
          DateAdded: null,
        };
        this.tempId = null;
      } else {
        console.log("error updating client aim");
      }
    },
    deleteItem(selectedItem) {
      const index = this.$store.state.plan.DigitalPlan.ActionPlan.findIndex(
        (x) => x.Id == this.tempId
      );
      this.$store.state.plan.DigitalPlan.ActionPlan[index].Updates.splice(
        this.$store.state.plan.DigitalPlan.ActionPlan[index].Updates.findIndex(
          (e) => e.Id == selectedItem.Id
        ),
        1
      );
      this.tempId = null;
    },
  },
};
</script>

